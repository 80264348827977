import * as React from "react"

import { createUseStyles } from 'react-jss'
import Layout from "../components/layout"
import Seo from "../components/seo"
import ContentArea from "../components/contentArea"
import Heading from "../components/heading"
import "../styles/themify-icons.css"

const useStyles = createUseStyles({
    locationContainer: {
        display: 'flex',
        flexWrap: 'wrap'
    },
    detailsContainer: {
        width: '100%',
        '@media(min-width: 992px)': {
            flex: '0 0 41.666667%',
            maxWidth: '41.666667%'
        }
    },
    detailsContent: {
        marginBottom: 12,
        display: 'flex',
        alignItems: 'flex-start',
        '@media(min-width: 992px)': {
            paddingRight: 15
        }
    },
    detailsContentBody: {
        flex: '1'
    },
    detailsIcon: {
        marginRight: 20,
        fontSize: 27,
        color: '#8f9195',
        lineHeight: '32px'
    },
    detailsParagraph: {
        marginTop: 0,
        marginBottom: '1em'
    },
    mapContainer: {
        width: '100%',
        '@media(min-width: 992px)': {
            flex: '0 0 58.333333%',
            maxWidth: '58.333333%'
        }
    },
    officeMap: {
        width: '100%',
        height: 500
    },
    footnote: {
        textAlign: 'center',
        paddingTop: '2em'
    }
})

const LocationPage = () => {
    var styles = useStyles();

    return (
        <Layout pageName="location">
            <ContentArea>
                <Heading {...{ level: 1, content: "Clinic Address" }} />
                <div className={styles.locationContainer}>        
                    <div className={styles.detailsContainer}>
                        <div className={styles.detailsContent}>
                            <span className={styles.detailsIcon}><i className="ti-home"></i></span>
                            <div className={styles.detailsContentBody}>
                                <Heading {...{ level: 5, content: "North York, Ontario" }} />
                                <p className={styles.detailsParagraph}>245 Fairview Mall Drive, Unit 502</p>
                            </div>
                        </div>
                        <div className={styles.detailsContent}>
                            <span className={styles.detailsIcon}><i className="ti-email"></i></span>
                            <div className={styles.detailsContentBody}>
                                <Heading {...{ level: 5, content: "Contact" }} />
                                <p className={styles.detailsParagraph}>To contact a specific provider, please see our <a href="referrals"> Referrals</a> page.</p>
                            </div>
                        </div>
                    </div>
                    <div className={styles.mapContainer}>
                        <iframe className={styles.officeMap} frameBorder="0" src="https://www.google.com/maps/embed/v1/place?q=245+fairview+mall+drive,north+york,ON&amp;key=AIzaSyBZkh1SHQONXfCfiqcO6faWo8XywOLBNE4"></iframe>
                    </div>
                </div>
                <div className={styles.footnote}>
                    <i>Please note: visits to the clinic are by appointment only; no walk-ins will be seen.</i>
                </div>
            </ContentArea>
        </Layout>
    )
}

export const Head = () => <Seo title="Location" />

export default LocationPage
